<template>
  <div>
    <!-- 全选 -->
    <el-checkbox
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
    >
      <span style="font-weight: bold;">{{ $t('selectAll') }}</span>
    </el-checkbox>
    <hr color="#F6F6F6" />
    <!-- 选项 -->
    <div v-for="(item, index) in data" :key="item.id">
      <!-- 子选项 -->
      <div v-for="(item2, index2) in item.resourceVos" :key="item2.id">
        <checkbox-group
          v-model="ids[item2.id]"
          :label="item.name == item2.menu
            ? labelText('menu', item2)
            : `${labelText('name', item)} - ${labelText('menu', item2)}`"
          :checkboxOptions="item2.resourceVos"
          @change="changeCheck($event, item2.resourceVos, item2.id)"
        ></checkbox-group>
        <hr color="#F6F6F6" v-show="item.resourceVos.length != index2 + 1" />
      </div>
      <hr color="#F6F6F6" v-show="data.length != index + 1" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { CheckboxGroup } from '@/components'

export default {
  name: 'function',
  components: { CheckboxGroup },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Array,
    data: Array,
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      ids: {},
    }
  },
  watch: {
    ids: {
      deep: true,
      handler(value) {
        let arr = [],
          num = 0
        Object.entries(value).forEach((e) => {
          for (let group of this.data) {
            for (let item of group.resourceVos) {
              // 找到组头
              if (item.id == e[0]) {
                let groupHeader = item
                if (Array.isArray(e[1])) {
                  // 子选项全选才添加组头
                  if (groupHeader.resourceVos.length == e[1].length) {
                    arr.push(+e[0])
                    num++
                  }
                  e[1].forEach((ee) => {
                    arr.push(ee)
                    num++
                  })
                } else {
                  e[1] && arr.push(+e[0])
                }
              }
            }
          }
        })
        if (num === value.length) {
          this.checkAll = true
        }
        this.$emit('change', arr)
      },
    },
    data(value) {
      let obj = {}
      let num = 0
      this.recursion(value, (item, parentId) => {
        if (item.selected) {
          if (item.menu) {
            obj[item.id] = true
          }
          if (item.rsName) {
            if (!Array.isArray(obj[parentId])) {
              obj[parentId] = []
            }
            obj[parentId].push(item.id)
          }
        }
        if (item.menu) num++
      })
      this.ids = obj
      if (Object.keys(obj).length == num) this.checkAll = true
    },
  },
  computed: {
    ...mapState('memory', ['language']),
    // 选项文字（根据语言显示）
    labelText() {
      return (type, item) => this.language === 'en'
        ? item[type]
        : item[`${type}Cn`]
    }
  },
  methods: {
    // 修改勾选（若勾选内容不包含查看权限，自动勾选）
    changeCheck(checkIds, functionList, groupName) {
      let readPermission = functionList.find(item => item.rsName.endsWith('list'))
      if (readPermission && !checkIds.includes(readPermission.id) && checkIds.length) {
        this.ids[groupName] = [
          ...checkIds,
          readPermission.id,
        ]
      }
    },
    handleCheckAllChange() {
      if (this.checkAll) {
        let obj = {}
        this.recursion(this.data, (item, parentId) => {
          if (item.menu) {
            obj[item.id] = true
          }
          if (item.rsName) {
            if (!Array.isArray(obj[parentId])) {
              obj[parentId] = []
            }
            obj[parentId].push(item.id)
          }
        })
        this.ids = obj
      } else {
        this.ids = {}
      }
    },
    // 递归
    recursion(data, callback, parentId) {
      data.forEach((e) => {
        callback && callback(e, parentId)
        if (Array.isArray(e.resourceVos) && e.resourceVos.length) {
          this.recursion(e.resourceVos, callback, e.id)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>