<template>
  <el-tabs
    v-model="activeName"
    type="card"
    stretch
  >
    <el-tab-pane :label="$t('selectSite')" name="site">
      <el-tree
        ref="tree"
        node-key="id"
        :data="data"
        :props="props"
        show-checkbox
        :default-expanded-keys="this.value[0]"
        :default-checked-keys="this.value[0]"
        @check-change="handleCheckChange"
      ></el-tree>
    </el-tab-pane>
    <el-tab-pane :label="$t('selectEnterprise')" name="enterprise" v-if="loginInfo.enterpriseType != 'enterprise'">
      <transfer-enterprise ref="enterprise" v-model="enterpriseData"/>
    </el-tab-pane>
    <el-tab-pane :label="$t('selectChannel')" name="channel" v-if="loginInfo.enterpriseType != 'enterprise'">
      <transfer-channel ref="channel" v-model="channelData"/>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapState } from 'vuex'
import { selectSitePermission } from '@/api/ssite'
import { TransferEnterprise, TransferChannel } from '@/components'

export default {
  name: 'data-permission',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Array,
    permissionType: {
      type: String,
      default: '',
    },
  },
  components: {
    TransferEnterprise,
    TransferChannel
  },
  data() {
    return {
      data: [], // 站点数据
      siteData: [],
      activeName: 'site',
      props: {
        value: 'id',
        children: 'childSite',
        label: 'siteName',
      },
    }
  },
  computed: {
    ...mapState('account', ['loginInfo']),
    enterpriseData: {
      get() {
        return this.value[1]
      },
      set(val) {
        this.$emit('change', [this.siteData, val, this.channelData])
      }
    },
    channelData: {
      get() {
        return this.value[2]
      },
      set(val) {
        this.$emit('change', [this.siteData, this.enterpriseData, val])
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      let params = {}
      selectSitePermission(params)
        .then(({ data }) => {
          this.data = data.result.rows
        })
        .catch(() => {})
    },
    handleCheckChange() {
      this.siteData = this.$refs.tree.getCheckedKeys()
      this.$emit('change', [this.siteData, this.enterpriseData, this.channelData])
    },
  },
}
</script>
