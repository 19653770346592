<template>
  <div class="page">
    <div class="page-main" ref="pageMain">
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item :label="$t('user.roleName')" prop="roleName">
          <el-input
            v-model="form.roleName"
            :placeholder="$t('user.roleNamePla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.roleDes')" prop="roleDescription">
          <el-input
            v-model="form.roleDescription"
            :placeholder="$t('user.roleDescriptionPla')"
            :maxlength="500"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.selectPermissions')" prop="resource">
          <el-tabs v-model="selectPermissionsActiveName">
            <el-tab-pane
              class="tab-function"
              :style="{height: tabMainHeight}"
              :label="$t('user.function')"
              name="function"
            >
              <function
                v-model="form.resource"
                :data="functionData"
              />
            </el-tab-pane>
            <el-tab-pane
              class="tab-dataPermission"
              :style="{height: tabMainHeight}"
              :label="$t('user.dataPermission')"
              name="dataPermission"
            >
              <data-permission
                ref="dataPermission"
                v-model="form.dataIds"
                :permissionType="permissionType"
              ></data-permission>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item class="footer-btns">
          <el-button class="footer-btn" @click="handleClose">{{ $t('cancel') }}</el-button>
          <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { addRole, roleQuery, allResourcesQuery } from '@/api/user'
import Function from './function'
import DataPermission from './data-permission'

export default {
  name: 'dialog-add-role',
  components: { Function, DataPermission },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('user.addRole')
      },
    },
    req: Object,
  },
  data() {
    return {
      permissionType: 'site',
      tabMainHeight: '500px',
      form: {
        roleName: '',
        roleDescription: '',
        resource: [],
        dataIds: [[],[],[]],
      },
      rules: {
        roleName: {
          required: true,
          message: this.$t('user.userName'),
          trigger: 'blur',
        },
        resource: {
          required: true,
          validator: (rule, value, callback) => {
            if (!value.length) {
              callback(new Error(this.$t('user.resourceRule')))
            } else if (!this.form.dataIds[0].length) {
              callback(new Error(this.$t('user.dataIdsRule')))
            } else {
              callback()
            }
          },
          trigger: 'change',
        },
      },
      functionData: [],
      selectPermissionsActiveName: 'function',
    }
  },
  computed: {
    ...mapState('account', ['loginInfo'])
  },
  created() {
    this.id = this.$route.query.id
    if (this.id) {
      this.getData()
    } else {
      this.getResources()
    }
  },
  mounted() {
    let pageHeight = window.getComputedStyle(this.$refs.pageMain).height
    this.tabMainHeight = `calc(${pageHeight} - 460px)`
  },
  methods: {
    // 获取数据
    getData() {
      roleQuery({
        enterpriseType: this.loginInfo.enterpriseType,
        roleId: this.id,
      })
        .then(({ data }) => {
          const { id, roleName, roleDescription, resource, siteIds, enterprise, channel } = data.result.rows[0]
          let dataIds = []
          dataIds[0] = siteIds ? siteIds : []
          dataIds[1] = enterprise ? enterprise : []
          dataIds[2] = channel ? channel : []
          this.form.id = id
          this.form.roleName = roleName
          this.form.roleDescription = roleDescription
          this.form.dataIds = dataIds
          this.functionData = resource
          this.$refs.dataPermission.$refs.enterprise.getTabelData()
          this.$refs.dataPermission.$refs.channel.getTabelData()
        })
        .catch(() => {})
    },
    // 获取权限列表
    getResources() {
      allResourcesQuery({
        enterpriseType: this.loginInfo.enterpriseType,
        env: 'en',
      })
        .then(({ data }) => {
          this.functionData = data.result.rows[0]
        })
        .catch(() => {})
    },
    // 取消
    handleClose() {
      this.$router.go(-1)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            dataIds: [
              ...this.form.dataIds[0],
              ...this.form.dataIds[1].map(e => e.id),
              ...this.form.dataIds[2].map(e => e.id)
            ]
          }
          addRole(params)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.handleClose()
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@include form-page;
.page {
  .page-main {
    padding: 64px 140px 20px 100px;
    .el-tab-pane {
      min-height: 200px;
      overflow-y: scroll;
      border: 1px solid #C6C6C6;
      border-radius: 6px;
    }
    .tab-function {
      padding: 10px 30px;
    }
  }
}
</style>